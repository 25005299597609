export const ESC_KEY_CODE = 27;
export const LEFT_KEY_CODE = 37;
export const RIGHT_KEY_CODE = 39;

export const SHARING_BUTTONS_WIDGET_SELECTOR = '.js-widget[data-widget="sharingButtons"]';
export const SHARING_BUTTONS_WIDGET_NAME = 'sharingButtons';
export const GALLERY_WIDGET_SELECTOR = '.js-widget[data-widget="gallery"]';
export const GALLERY_WIDGET_NAME = 'gallery';
export const EMBED_CODE_WIDGET_SELECTOR = '.js-widget[data-widget="embedCode"]';
export const EMBED_CODE_WIDGET_NAME = 'embedCode';
export const HEADER_WIDGET_SELECTOR = '.js-widget[data-widget="header"]';
export const HEADER_WIDGET_NAME = 'header';
export const SLIDER_WIDGET_SELECTOR = '.js-widget[data-widget="slider"]';
export const SLIDER_WIDGET_NAME = 'slider';
export const PHOTO_CODE_WIDGET_SELECTOR = '.js-widget[data-widget="photo"]';
export const PHOTO_CODE_WIDGET_NAME = 'photo';
export const INSTAGRAM_WIDGET_NAME = 'instagram';
export const INSTAGRAM_WIDGET_SELECTOR = '.js-widget[data-widget="instagram"]';
export const VIDEO_WIDGET_SELECTOR = '.js-widget[data-widget="video"]';
export const VIDEO_WIDGET_NAME = 'video';
export const TWITTER_WIDGET_SELECTOR = '.js-widget[data-widget="twitter"]';
export const TWITTER_WIDGET_NAME = 'twitter';
export const SVG_LOADER_WIDGET_SELECTOR = '.js-widget[data-widget="svgLoader"]';
export const SVG_LOADER_WIDGET_NAME = 'svgLoader';
export const FORM_WIDGET_SELECTOR = '.js-widget[data-widget="form"]';
export const FORM_WIDGET_NAME = 'form';
export const BLOG_SOCIAL_WIDGET_SELECTOR = '.js-widget[data-widget="blogSocialButton"]';
export const BLOG_SOCIAL_WIDGET_NAME = 'blogSocialButton';
export const POST_IMAGE_SELECTOR = '.js-widget[data-widget="postImage"]';
export const POST_IMAGE_NAME = 'postImage';
export const ITEMS_VIEW_SELECTOR = '.js-widget[data-widget="itemsView"]';
export const ITEMS_VIEW_NAME = 'itemsView';
export const ECOM_PRODUCT_SELECTOR = '.js-widget[data-widget="ecommerceProduct"]';
export const ECOM_PRODUCT_NAME = 'ecommerceProduct';
export const COOKIE_NOTICE_SELECTOR = '.js-widget[data-widget="cookieNotice"]';
export const COOKIE_NOTICE_NAME = 'cookieNotice';
export const PROMOTION_NAME = 'promotion';
export const PROMOTION_SELECTOR = '.js-widget[data-widget="promotion"]';
export const FACEBOOK_NAME = 'facebook';
export const FACEBOOK_SELECTOR = '.js-widget[data-widget="facebook"]';
export const ECOMMERCE_PRODUCT_SELECTOR = '.js-widget[data-widget="ecommerce-product"]';
export const ECOMMERCE_PRODUCT_NAME = 'ecommerce-product';
export const CART_BUTTON_SELECTOR = '.js-widget[data-widget="ecome-cart"]';
export const CART_BUTTON_NAME = 'ecome-cart';
export const SOCIAL_BUTTON_SELECTOR = '.js-widget[data-widget="social-button"]';
export const SOCIAL_BUTTON_NAME = 'social-button';
export const PINTEREST_BUTTON_NAME = 'pinterest';
export const PINTEREST_BUTTON_SELECTOR = '.js-widget[data-widget="pinterest"]';
export const DISQUS_NAME = 'disqus';
export const DISQUS_SELECTOR = '.js-widget[data-widget="disqus"]';
export const CURRENT_PAGE_ANCHOR_CLASS_NAME = 'js-current-page-anchor';
